.App {
    text-align: center;
}

.btn-teste{
    border:1px solid #000000;
    padding: 5px;
    border-radius: 4px;
    background-color: rgb(236, 245, 245);
    text-decoration: none;
    border-bottom: 5px;
}

.mt-page{
    border: 0px;
    background-color: hfff; 
    height: 35px; 
    cursor: pointer;
}

.form-pedido select{
    border: 0px;
    background-color: hfff; 
    height: 35px; 
    cursor: pointer;  
}

h3 {
    font-size: 30px;    
}


#id_css {
    background-color: rgb(250, 246, 246); 
    padding: 80px 0 0 0; 
       
}

#id_css_1 {
    background-color: rgb(221, 168, 99);
    color: blue; 
    font-size: 20px;  
}

#id_css_2 {
    background-color: rgb(113, 221, 99);
    color: red; 
    font-size: 20px; 
    text-align: center;
    padding: 1%; 
    
}

#id_css_3 {
    background-color: rgb(216, 215, 231);    
    text-align: center;
    padding: 2%; 
}


#id_css_3 img{   
    width: 80%;
    border-radius: 5%;     
    text-align: center; 
    margin-top: 50px;    

}

.css_margin_1{
    margin: 10px 0 8px 10px;
}

.css_margin_2{
    margin:10px 0 10px 10px;    
}









