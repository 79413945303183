
#css_profissao_primeiro {
    /*background-color: rgb(90, 241, 76);*/
    width: 380px;
    height:100vh; 

    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    /*background-color: rgb(185, 116, 26); */
    background-color: black;
}



.css_tabela_3 {
    width: 330px;
    height: 300px; 
    /*background-color: rgb(90, 241, 76);*/
    margin-bottom: 200px;

    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center;
}


/* Ocultar uma Coluna  */
#ocultar_1 {
    visibility: collapse;    
}

#largura_botao_7 {
    width: 290px;  
    margin-left: 20px;   
}

#teste_4 {

    /*background-color: rgb(106, 76, 241); */
    width: 310px;   

    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: center;
   
}

#teste_55 {
    margin-left: 20px; 
    margin-top: 30px;     
}





