.css-teste2{
    background-color: rgb(237, 240, 100);
    text-align: center;
    padding: 5%;
       
    
}

.css-teste3{
    background-color: chartreuse;
    text-align: center;
    padding: 5%;   
}

/* para todos os Parágrafor mudar a Cor*/
p {
    color: #8b8b8b;
}

/* estudo com FLEX*/ 

#css_q{
    background-color: aquamarine;
    height: 400px;
    margin-top: 20px;

    display: flex;
    /*flex-direction: column;*/
    /*justify-content: flex-start;*/
    /*align-items: center;*/
    /*flex-wrap: nowrap;*/
    gap: 1em;
   
}

#css_q1{
    background-color: chartreuse;
    height: 40px; 
}

#css_q2{
    background-color: red;  
    height: 40px;
    /*flex-grow: 1;*/
    /*flex-basis: 500px;*/     
}

#css_q3{
    background-color: blue;  
    height: 40px; 
    flex: 1;
} 