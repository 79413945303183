      
  
  .imputs-teclado {   
    display:flex;
    flex-direction: column;  
    justify-content: flex-start; 
    align-items: center;
    
    
  }
  
  .teclado button {
    padding:20px;
    font-size: 24px;
    border: none;
    border-radius: 10px;
    cursor: pointer;  
  }

  .teclado {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;    
  }
  
  .btn {    
    color: blue;
    background-color: white;
    
  }

  .btn:hover {
    color: blue;
    background-color: white;
  }
  
  .del {
    background-color: orange;
  }
  
  .sair:hover {
    background-color: rgb(102, 101, 101);
    color: #ff0000;
  }

  .del:hover {
    background-color: rgb(102, 101, 101);
    color:  rgb(255, 153, 0);
  }
  
  .sair {
    background-color: #ff0000;
    color: #fff;
  }

  #abelha {    
    width: 100px;     /*largura fixa da imagem*/ 
    height: 100px;   /*Altura fixa da imagem*/
    
    
}

#css_parte_1{
  margin-top: 20px; 
  height: 80px;   /*altura fixa da div*/ 
  width:120px;     /*largura fixa da imagem*/  
 
}

#css_senha {
  height: 35px; 
  width: 267px;
  color: blue;
  background-color: white;  
     
}

#css_senha:hover {
  background-color:  blue;
  color:white; 
}

#css_botao {
  height: 35px; 
  width: 267px;
  background-color: blue;
  color: white;     
}

#css_botao:hover {
  background-color: white; 
  color: blue;   
}



#div_botao {  
  height: 50px;  
}
