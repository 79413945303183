
#tela_inicial_T {
    width: 1250px;
    height: 100%;  
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
  }


  #tela_55 {  
    width: 1250px; 
    height: 50px; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  
  #tela_57 {   
      
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
  }

  #css_509{
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
    align-items: center;
  }

  #css_501 {
    width: 120px;   
  }

  #css_500 {

    width: 150px; 
 

  }

  #Parte_Principal_2_Lados {
    width: 1250px;
    display: flex;
    flex-direction: row;
    justify-content: space-around; 

  }

  #css_505_lado_esquerdo {
    height: 400px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
     

  }

  #css_505_lado_direito {    
    width: 800px; 
  }

  #css_505{
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 

  }

  

  #largura_btn_50 {
    width: 110px;
    height: 45px;
  }

  #css_400{
    color: rgb(7, 11, 243);  
  }

  #css_5005 {
    width: 1300px;

    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;   
  }
 
 