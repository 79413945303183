
#body{
   /* background-color: chocolate;  */  
    
   /*height: 100vh;  */
   
    display: flex;
    justify-content: center;
    align-items: center;  
    
    background-color: black;
}

#css_principal_perfil {
    /*background-color: rgb(90, 241, 76);*/
    width: 360px;
    height:100vh; 

    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: center;
    

    /* borda de Estudo */
    border: 0px solid rgb(117, 5, 245);
  
}

.item {
    width: 100%;    
    /*background-color: rgb(231, 33, 142);*/
    border: 0px solid rgb(29, 27, 27);   
    
}

.item-1 {
    height: 100px;
}

.item-4 {
    height: 180px; 
}

.item-3 {   

    display: flex;    
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 20px;
    padding-right: 20px;
    

    }

#id_perfil {
    border: 0px solid rgb(189, 221, 45);   
}

.btn_editar {
    display: flex;  
    justify-content: center;

}

.id_elemento {
    width: 100%;
}

#largura_botao {
    width: 100%;
    height: 30px; 
}

#largura_botao_2 {
    width: 70px;   
}

.Nome_Campos {
    font-size: 12px;
    padding-left: 10px;   
    margin: 0;
}

#css_tipo_pix{
  display: flex;
  justify-content: space-between; 
  
  
}

#css_tipo_pix_item{
    border: 1px solid rgb(29, 27, 27);
    width: 70px;
    padding: 0;
    margin: 0;  

    display: flex;
    justify-content: center;    

    height: 28px;

    cursor: pointer;

    border-radius: 15%;
    
    
  }

  #m_it {
    margin-top: 4px;
  } 

  #css_botao_sair8 {     
    width: 80px; 
    height: 40px; 
    margin-top: 10px; 
}