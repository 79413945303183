
#tela_inicial_T2 {
    width: 1350px;
    height: 100%;  
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  #tela_da_esquerda_T2 {   
    width: 500px;  
    margin-top: 15px;
  }

  #tela_da_direita_T2 {    
    width: 750px;
    margin-top: 15px;  
 
  }

  #tela_55_V9 {   
    margin-top: 20px;  
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  #tela_55_V9B {   
    margin-top: 12px;  
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  #css_501 {
    width: 45px;   
  }

  #css_500 {

    width: 150px;    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  #css_grid_V3{
    margin-top: 20px;
  }



  #css_62_F3 { 
    
    width: 600px;  
    display: flex;
    flex-direction: row;
    justify-content: space-between;     

  }

  #largura_btn_50_F3 {
    width: 140px;
  }

  #css_separa_div{
    
    margin-top: 25px;
    margin-bottom: 25px;
    width: 750px;  
    display: flex;
    flex-direction: row;
    justify-content: space-between; 

  }