

*{
    font-size: medium;
    
}

#css_texto_inicial {
    margin-bottom: 10px; 
    color: rgb(7, 11, 243); 
}

#css_cor_texto {
    margin-bottom: 20px; 
    color: rgb(8, 8, 8); 
}

#ajuste11 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;     
}

#ajuste12 {
    width: 120px;    
}

#tam_maior_12 {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
}

#tam_esquerda_12 {
    width: 700px;
}

#tam_direita_12 {
    width: 400px;
   
    margin-top: 12px;
    
}


