#css_principal2{ 
    margin-top: 85px;
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: column;   /*elementos na vertical*/     
    height: 100vh;  /*toda altura*/     
    justify-content: flex-start;   
    align-items: center;   /*parte de meio da div */   
    gap:2em;    
    /*background-image: linear-gradient(-225deg, #65379B 0%, #886AEA 53%, #6457C6 100%); */    
    background-color: black;
   
}

#css_botao_Principal2 {
    margin-bottom: 40px;  
    width: 320px;      
}

#css_abertura {
    
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: column;   /*elementos na vertical*/          
    justify-content: flex-start; 
    align-items: center; 
    /*background-color: blue; */     
}

#css_botoes2 {
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: column;   /*elementos na vertical*/          
    justify-content: center;      
    align-items: center; 
    /*background-color: brown; */
    gap:1em;
    
}

#css_celula {
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: row;   /*elementos na vertical*/          
    justify-content: space-between;      
    align-items: center;    

}

#css_parte_3A{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;  
  
      
}

#css_parte_4A{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 330px; 
    gap:1em;      
}

