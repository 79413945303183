



#cabecalho {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: row;   /*elementos na vertical*/          
    justify-content: center; 
       
}

#planilha {
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: row;   /*elementos na vertical*/          
    justify-content: center; 
    

}