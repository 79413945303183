
#devedores_principal{
    width: 1350px;
}


#Separa_Lista_Botao{
    margin-top: 30px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
