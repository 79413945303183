

*{
    font-size: medium;
    
}

#css_texto_inicial {
    margin-bottom: 40px; 
    color: rgb(8, 8, 8); 
}

#css_cor_texto {
    margin-bottom: 20px; 
    color: rgb(8, 8, 8); 
}







