#tela_inicial_V1 {

    width: 1350px; 
    
     
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  #css_organiza{
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 

  }

  #tela_da_esquerda_V1 {   
    width: 480px;  

 
  }

  #tela_da_direita_V1 {    
    width: 840px; 
   
  }

  #tela_55_V1 { 
    height: 44px;  
      
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    margin-top: 10px; 
  }

  
  #tela_57 {   
      
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
  }

  #css_501 {
    width: 45px;   
  }

  #css_500 {

    width: 150px;    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  #largura_btn_50 {
    width: 90px;
  }

  #css_400{
    color: white;  
  }
 