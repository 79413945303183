
#tela_inicial_k {
  width: 1350px; 
    height: 100%;  
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
  }


  #tela_55 {   
    height: 100px; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  
  #tela_57 {   
      
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
  }

  #css_509{
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
    align-items: center;
  }

  #css_501 {
    width: 45px;   
  }

  #css_500 {

    width: 150px; 

 

  }

  #css_500_K {    
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  

  }

  #css_505_V2 {

    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;   

  }

  #largura_btn_50 {
    width: 70px;    
  }

  #css_400{
    color: rgb(7, 11, 243);  
  }

  #largura_btn_50Z{
    width: 45px;
  }
 