.Footer {   

    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
    align-items: center;
    font-size: 17px;
    padding: 25px 0;

    position: fixed;
    bottom: 0;
    width: 100%;
}

/*background-color: black;

color: rgba(255, 2555, 2555, 0.85);

*/