



#cabeca_2 {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: row;   /*elementos na vertical*/          
    justify-content: center;            
}

#planilha_7x {
    font-size:smaller;
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: row;   /*elementos na vertical*/          
    justify-content: center;            
}

