

*{
    font-size: medium;
    
}

#css_texto_inicial {
    margin-left: 20px; 
    color: rgb(7, 11, 243); 
}

#css_cor_texto {
    margin-bottom: 20px; 
    color: rgb(17, 116, 38); 
}

#ajuste11 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;     
}

#ajuste12 {
    width: 120px;    
}

#tela_maior {
   
  background-color:white;
    
  height: 100%;

  width: 1300px;
  

  display: flex;
  flex-direction: row;
  justify-content: center;


}

#tela_esquerda {   
    margin-right: 30px;
    width: 550px;
}   

#grid_lado_esquerdo {    
    width: 550px;    
}

#tela_direita {
    
    width: 650px;
}

#grid_lado_direito {    
    width: 650px;
    height: 2%;  
}

#css_50 {
    height: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-start;    
}

#css_51 {
    margin-top: 15px;
    margin-bottom: 10px;
    height: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
}

#css_52 {    
    color: rgb(7, 11, 243);  
}

#css_53 {    
    color: rgb(7, 11, 243);  
}



#css_54 {   
    width: 130px;  
}

#css_55 {
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
}

#css_56 { 
    margin-top: 10px;   
    display: flex;
    flex-direction: row;
    justify-content:  space-between;    
}

#css_57 {      
    display: flex;
    flex-direction: row;
    justify-content:  space-between;    
}

#css_125 {
       
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  #css_126 {
    width: 100px;
  }

  #css_127 {
    width: 130px;
  }

  #largura_botoes_V35{
    width: 110px;
  }

 



