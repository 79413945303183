

.form-container{
    height: 100vh; 
    width: 100vw; 
    background-color: black;
}


.form-signin{ 
    display: flex;    /*inicia o flexbox*/ 
    flex-direction: column;   /*elementos na vertical*/     
    height: 80vh;  /*toda altura*/   
}

#css_parte_1{
    display: flex;
    justify-content: center;   /*meio horizontal da div*/
    align-items: flex-end;   /*parte de baixo da div*/  

    height: 200px;   /*altura fixa da div*/
      
}

#abelha {    
    width: 100px;     /*largura fixa da imagem*/ 
    height: 100px;   /*Altura fixa da imagem*/
}

#css_parte_2{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    
    flex-grow: 1;   /*altura que sobra das outras 2 div*/     
       
}

#css_parte_3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

    height: 200px;   
      
}



#css_parte_4{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px; 
    margin-bottom: 90px;
        
}



#css_email {
     margin-bottom: 20px;    
     opacity: 0.8;
     width: 320px;
     height: 45px;         
}



#css_senha {
    margin-bottom: 20px;    
    opacity: 0.8;
    width: 320px;  
    height: 45px;        
}


#css_botao {
    margin-bottom: 150px;  
    width: 320px;     
}


#css_sidyna{
    font-size: 8pt;
}

#xxxx {
    color: white;
}

#id_perfil_5 {
    border: 0px solid rgb(189, 221, 45);   
}

.Nome_Campos_5 {
    font-size: 12px;
    padding-left: 10px;   
    margin: 0;
    
    color: rgb(255, 255, 255);
}

#largura_botao_5 {
    width: 100%;     
}





