
#css_hotel_primeiro {
    /*background-color: rgb(90, 241, 76);*/
    width: 380px;
    height:100vh; 

    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center;
    /*background-color: rgb(185, 116, 26);*/
    background-color: black;
}

.css_tabela_33 {
    width: 330px;
    height: 100vh-50px; 
    /*background-color: rgb(90, 241, 76);*/

    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center;
}





/* Ocultar uma Coluna  */
#ocultar_2 {
    visibility: collapse;
}

#cabeca {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

#centralizar {
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
    color: rgb(29, 119, 204);


}

/*color: rgb(29, 119, 204);*/

#espaco_acima_botao {
    margin-top: 15px;
}

#css_botao_sair6 {     
    width: 80px; 
    height: 40px;  
}

#margenzinha {
    margin-bottom: 10px;
    width: 300px;
}