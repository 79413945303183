.loader_container_1 {

	background-color:#ee0e0e; 

	height:800px;
	width:600px;
	
	opacity: 0.85;
	position: relative;

}

.loader_container_2 {    
    background-color:#ee0e0e; 
    position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
		
}



