
#css_60 {   
    width: 700px;  
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
     
  }

  #css_61 {     
    width: 150px; 
  }

  #css_62_F1 { 
    margin-top: 35px;
    margin-bottom: 80px;
    width: 800px;  
    display: flex;
    flex-direction: row;
    justify-content: space-around;     

  }

  #largura_btn_50_F {
    width: 140px;
  }






