#tela_casulo {
    width: 100%;   
    height:600px; 
    display: flex;
    flex-direction: row;
    justify-content: center; 

}



#tela_inicial_V1 {
    
    width: 100%;   
    height:600px; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    
    max-width: 700px;
 
  }

  #tela_55_V1 { 
    
    
     
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-top: 10px;
    margin-left: 5px; 
    margin-right: 5px;
  }

  
  #tela_57 {   
      
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
  }

  #css_501 {
    width: 45px;   
  }

  #css_500 {

    width: 150px;    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  #largura_btn_50 {
    width: 90px;
  }

  #css_400{
    color: rgb(7, 11, 243);  
  }
  
  .Tam_fonte {
    font-size: 15px;    
    height: 7px;
    color: black;
          
  }