
#id_div_primaria{
   
    display:flex;
    flex-direction: row;
    justify-content: center;

}

#id_div_secundaria{
    
    height: 600px;
    width: 700px;    
    margin-right: 50px;
    margin-left: 50px;    
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


#largura_botoes{
    width: 300px;
}


#operador_51{
    margin-top: 50px;
   
}
