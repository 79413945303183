
#tela_inicial_V1 {

    width: 1350px; 
    
    height: 100%;  
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  #css_organiza{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 

  }

  #tela_da_esquerda_V1 {   
    width: 480px;  

 
  }

  #tela_da_direita_V1 {    
    width: 840px; 
   
  }

  #tela_55_V1 {   
      
    display: flex;
    flex-direction: row;
    justify-content: space-around; 
    margin-top: 10px; 
  }



  
  #tela_57 {   
      
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
  }

  #css_501 {
    width: 45px;   
  }

  #css_500 {

    width: 150px;    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  #largura_btn_50 {   
    min-width: 120px;
  }

  #css_400{
    color: rgb(7, 11, 243);  
  }

  #css_grid_1{
    
    width: 700px;  
  }

  #tela_55D {  
    width: 700px; 
    height: 50px; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;  
  }

  #css_cab{
    margin-top: 20px;
    margin-left: 20px;
   
    width: 600px; 
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #css_cab_esq{
     margin-right: 60px;
  }
 